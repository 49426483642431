@use '@angular/material' as mat;
@include mat.core();

$md-ford: (
	50: #e0e7ef,
	100: #b3c2d7,
	200: #809abc,
	300: #4d71a1,
	400: #26528c,
	500: #003478,
	600: #002f70,
	700: #002765,
	800: #00215b,
	900: #001548,
	A100: #7c93ff,
	A200: #496aff,
	A400: #1640ff,
	A700: #002dfc,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #ffffff,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #ffffff,
		A400: #ffffff,
		A700: #ffffff
	)
);

$md-ford-lime: (
	50: #e7efe4,
	100: #c3d6bb,
	200: #9cbb8e,
	300: #749f61,
	400: #568b3f,
	500: #38761d,
	600: #326e1a,
	700: #2b6315,
	800: #245911,
	900: #17460a,
	A100: #94ff7d,
	A200: #6aff4a,
	A400: #40ff17,
	A700: #2dfc00,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #000000,
		500: #000000,
		600: #000000,
		700: #000000,
		800: #000000,
		900: #000000,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);



/* ======== Angular material custom themes ======== */
$my-custom-primary: mat.define-palette($md-ford);
$my-custom-accent: mat.define-palette($md-ford-lime);
$my-custom-warn: mat.define-palette(mat.$lime-palette);
$my-custom-theme: mat.define-dark-theme((
	color: (
		primary: $my-custom-primary,
		accent: $my-custom-accent,
	),
));


@include mat.core-theme($my-custom-theme);
@include mat.all-component-themes($my-custom-theme);

@import '~quill/dist/quill.snow.css';

@import './theme/variables.scss';
@import './theme/utils.scss';
@import './theme/typography.scss';
@import './theme/overrides.scss';
@import './theme/main.scss';
@import './theme/tooltips.scss';
@import './theme/button-groups.scss';
@import './theme/table.scss';
@import './theme/widget.scss';
@import './theme/mat-button-toggle-group.scss';
@import './theme/light-dark-mode-overrides.scss';

/* DEFAULT GTB FORD BLUE STYLES */
:root {
	--font-family: 'Ford F-1', Arial, Helvetica, sans-serif;
	--font-family-bold: 'Ford F-1', Arial, Helvetica, sans-serif;


	/* default text color */
	--text-color: #FFF;
	--dark-text-color: #000;
	--contrast-text-color: #FFF;
	--button-contrast-color: #FFFFFF;


	/* color definitions */
	--color-primary: #FFFFFF;
	--primary-color: #FFFFFF;
	--secondary-light: #424242;
	--secondary-interactive: #222222;
	--secondary-medium: #CBCBCB;
	--secondary-medium-dark: #727272;
	--secondary-dark: #FFFFFF;

	--primary-interactive: #037;

	--contrast-color: #FFFFFF;

	/* New /
	/ background */
	--background-color: #171717;

	--background-contrast-color: #00095B;

	--foreground-color: #0a0a0a;
	--foreground-light-color: #1e1e1e;
	--foreground-shade-color: #141414;
	--foreground-border-color: transparent;

	/* table overrides */
	--table-background-color: #1e1e1e;
	--table-text-color: #FFFFFF;
	--table-border-color: #2B2B2B;
	--table-selected-background: #171717;

	/* mat-buttons */
	--primary-button-background-color: #037;
	--primary-button-color: #FFF;

	--secondary-button-color: #FFF;
	--secondary-button-hover-color: rgba(255, 255, 255, 0.1);

	--tertiary-button-color: #FFF;
	--tertiary-button-hover-color: rgba(255, 255, 255, 0.1);


	--destructive-button-hover-color: rgba(211, 81, 94, 0.85);

	/* toggle overrides */
	--toggle-background: #424242;
	--toggle-border: transparent;

	/* project thumbnail */
	--thumbnail-opacity: 0.8;

	/* Slide toggle */
	--slide-toggle-background: #CBCBCB;

	/* Form fields */
	--mat-form-field-background-color: #ffffff1a;

	/* Chip / Tag background*/
	--tag-background: #000000;
	--static-tag-background: #000;
	--static-tag-color: #FFF;

	/* tab specific */
	--tab-primary: #FFF;
	--tab-interactive: #FFF;

	/* ad-editor specific */
	--ad-editor-background-shade: #2b2b2b;
	--ad-editor-selected-background: #171717;
	--ad-editor-contrast-background: #333333;
	--ad-editor-property-group-contrast-background: #ffffff0f;

	--ad-editor-navigation-border: transparent;

	--ad-editor-primary-shade-text-color: #b3b3b3;

	--layer-header-background: #424242;
	--layer-content-background: #191919;
	--layer-content-border-color: #777777;

	/* video-builder specific */
	/* Content group*/
	--content-group-border: 1px solid transparent;
	--content-group-border-selected: #FFF;

	--content-group-background: #1e1e1e;
	--content-group-item-background: #292929;

	--content-group-item-text: #FFF;

	--video-builder-customization-background: #3a3e45;
	--video-builder-customization-shade-background: #2b2f35;

	--generated-voiceover-color: #2e2e2e;
	--generated-voiceover-selected-color: #3e3e3e;

	--visibility-condition--background: #ffffff1a;


	--ripple-header-background: #404040;
	--ripple-content-background: #ffffff0f;
	--ripple-border: #595959;
}

.steps a {
	font-family: 'Ford F-1', Arial;
}

app-editor-navigation .next, app-projects-navigation {
	--ion-font-family: 'Ford F-1', Arial;
}
