.mat-button-toggle-group {
	flex-grow: 0;
	flex-shrink: 0;

	.mat-button-toggle {
		display: flex;
		border: 1px solid transparent;
		background: transparent;
	}

	.mat-button-toggle {
		background-color: var(--toggle-background);
		border-color: var(--toggle-border);
		border-radius: 3px;

		.mat-button-toggle-label-content, .mat-button-toggle-button * {
			color: var(--primary-color);
		}

		&-checked, &.active {
			.mat-button-toggle-button, .mat-button-toggle-button * {
				background: var(--primary-interactive);
				color: var(--button-contrast-color);
			}

			.mat-button-toggle-label-content {
				z-index: 9;
				color: var(--button-contrast-color);
			}
		}
	}

	.mat-button-toggle-label-content {
		display: flex;
		padding: 0 20px;
		justify-items: center;
		font-weight: 700;
	}
}
