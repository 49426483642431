// Angular Material specific styles

// border color
.mat-form-field .mat-form-field-wrapper {
	border-color: var(--secondary-medium-dark) !important;

	.mat-form-field-flex {
		height: 100%;
		color: var(--primary-color) !important;
		background-color: var(--mat-form-field-background-color) !important;
		border-radius: 4px !important;
	}

	.mat-form-field-hint-wrapper {
		.mat-hint {
			color: var(--contrast-color);
		}
	}

	.mat-input-element:disabled {
		color: var(--secondary-medium) !important;
	}
}

.mat-paginator {
	.mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
		background: transparent !important;
	}

	.mat-button-wrapper {
		color: var(--primary-color)!important;
	}
}

.mat-button-toggle-group {
	border-color: var(--secondary-medium-dark) !important;
}


// color for placeholder
.mat-form-field input::placeholder {
	color: var(--secondary-medium-dark) !important;
}

.mat-select-placeholder {
	color: var(--secondary-medium-dark) !important;
}

// value color
.mat-select {
	.mat-select-min-line {
		color: var(--contrast-color) !important;
	}

	&.dark-text {
		background: var(--mat-form-field-background-color);

		.mat-select-min-line {
			color: var(--dark-text-color) !important;
		}
	}
}

/**************************/
/* Dialog specific styles */

// dialog content
.mat-dialog-container {
	background-color: var(--background-color) !important;

	.mat-dialog-content {
		color: var(--primary-color) !important;
	}

	// dialog header and title
	.mat-dialog-title {
		color: var(--primary-color) !important;

		* {
			color: var(--primary-color) !important;
			//background: var(--foreground-color) !important;
		}
	}

	// dialog footer
	.mat-dialog-actions {
		background: var(--background-color) !important;
	}
}

/*  Material Tab  */
.mat-tab-group {
	.mat-tab-body-content {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	&.extra-gap {
		.mat-tab-body-content {
			gap: 40px;
		}
	}

	.mat-tab-label {
		flex-grow: 1;

		.mat-tab-label-content {
			color: var(--tab-primary) !important;
		}

		&.mat-tab-label-active {
			.mat-tab-label-content {
				color: var(--tab-interactive) !important;
			}
		}

	}

	.mat-ink-bar {
		background-color: var(--tab-interactive) !important;
	}
}


/* Mat Checkbox */
.mat-checkbox {
	.mat-checkbox-inner-container {
		border: 1px solid var(--secondary-medium)!important;
		border-radius: 3px!important;
	}

	.mat-checkbox-frame {
		border: none;
	}
}

.mat-primary .mat-pseudo-checkbox-checked {
	background-color: var(--primary-interactive);
}

.mat-slide-toggle {

	// background specific
	.mat-slide-toggle-label .mat-slide-toggle-bar {
		background-color: var(--slide-toggle-background) !important;
	}

	&.mat-checked {
		.mat-slide-toggle-label .mat-slide-toggle-bar {
			background-color: var(--primary-interactive) !important;
		}

		.mat-slide-toggle-thumb {
			background-color: var(--primary-interactive) !important;
		}

	}

	.mat-slide-toggle-content {
		color: var(--contrast-color) !important;
	}

	&.contrast-color {
		.mat-slide-toggle-content {
			color: var(--contrast-color) !important;
		}
	}
}

/* Button */
.secondary-button {
	--text-color: var(--button-contrast-color);
	background-color: var(--secondary-medium-dark) !important;
}


/* select */
.mat-select {
	.mat-select-arrow-wrapper {
		.mat-select-arrow {
			color: var(--primary-color) !important;
		}
	}
}

/* datepicker */
.mat-datepicker-toggle {
	button.mat-icon-button .mat-datepicker-toggle-default-icon {
		--text-color: var(--primary-color);
		color: var(--primary-color) !important;
	}
}


textarea {
	color: var(--contrast-color) !important;

	&::placeholder {
		color: var(--secondary-medium-dark) !important;
	}
}


.mat-menu-panel, .mat-select-panel, .mat-autocomplete-panel {
	background-color: var(--secondary-light) !important;

	* {
		color: var(--text-color) !important;
	}

	.mat-menu-item:hover, .mat-option:hover {
		background-color: var(--secondary-interactive) !important;
	}
}

.tippy-box, .mat-tooltip, .mat-tooltip.mat-tooltip-show {
	background-color: var(--secondary-interactive) !important;
	color: var(--text-color);

	* {
		color: var(--text-color);
	}
}

.tippy-arrow::before {
	color: var(--secondary-interactive) !important;
}
