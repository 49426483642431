/*
	Put global styles that govern any new components or containers.
	Overrides to Ionic elements go in overrides.scss
*/

html,
body {
	background-color: var(--background-color);
}

app-root {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	position: absolute;
	flex-direction: column;
	contain: layout size style;
	overflow: hidden;
	z-index: 0;
}

.page-container {
	display: block;
	position: relative;
	flex: 1 1 0%;
	width: 100%;
	height: 100%;
	//height: calc(100% - var(--header-height));
	margin: 0px !important;
	padding: 0px !important;
	padding-bottom: 40px;
	font-family: var(--font-family);
	contain: size style;
	overflow: auto;
}

.fullscreen {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.container {
	display: block;
	margin: auto;
	padding: 40px 0 0;
	text-align: center;
	position: relative;

	& > button {
		margin-top: 30px;
	}
}

.content-group {
	background: var(--content-group-background);
	padding: 25px 25px;
	margin: 0 auto;
	margin-bottom: 30px;
	width: 100%;
	max-width: 980px;
	position: relative;
	//border: 1px solid var(--base-color);

	&.no-background {
		background: transparent;

		.content {
			border: 1px solid #2b2b2b;
			background: var(--content-group-background);
			padding: 8px 12px;
		}
	}

	.content {
		&:has(.mat-paginator) {
			padding-top: 0 !important;
		}
	}

	.title-container {
		margin-bottom: 25px;
	}

	h2,
	.title {
		margin-top: 0;
		margin-bottom: 5px;
		text-align: left;
		color: var(--text-color);
		font-family: var(--font-family-heading);
		font-weight: 700;
		font-size: 18px;
		line-height: 120%;

		&.gray {
			color: var(--color-gray);
		}

		&.lg {
			font-size: 22px;
		}
		&.xlg {
			font-size: 26px;
		}
	}

	.subtitle {
		text-align: left;
		margin: 0 0 5px 0;
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		color: var(--color-step-500);

		&.white {
			color: var(--background-contrast-color);
		}
		&.xl {
			font-size: 16px;
		}
		p {
			margin: 0;
		}
	}

	&__select {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 24px;
		background: var(--content-group-item-background);
		border: var(--content-group-border);
		border-radius: 4px;
		cursor: pointer;

		h3,
		p {
			padding: 0;
			margin: 0;
			font-family: var(--font-family);
			font-weight: 700;
			font-size: 14px;
			text-transform: uppercase;
			color: var(--primary-color);
		}

		p {
			font-weight: 400;
			text-transform: none;
			color: var(--contrast-color);
		}

		&--selected {
			border: 2px solid var(--content-group-border-selected);

			h3,
			p {
				color: var(--content-group-item-text);
				opacity: 1;
			}
		}

		&--small {
			max-width: 400px;
		}
	}

	ion-item,
	.form-item {
		--background: #2e2e2e;
		background: #2e2e2e;
		--color-activated: white;
		--color-focused: white;

		&.item-radio-checked,
		&.item-checkbox-checked {
			--background: var(--color-secondary);
		}
	}

	.form-item {
		border: 5px solid rgba(0, 0, 0, 0);
		transition: all 300ms;
		cursor: pointer;

		&:hover {
			background: #333;
		}

		&.selected,
		&.active {
			background: #292929;
			border: 2px solid #ffffff;
		}
	}
}

button.loading-spinner {
	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid white;
		border-top-color: transparent;
		animation: spinner 0.8s linear infinite;
	}

	.mat-button-wrapper {
		visibility: hidden;
		padding: 15px 5px;
	}

	&.button-extra-small .mat-button-wrapper {
		padding: 8px 5px;
	}
}
@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

mat-form-field {
	color: var(--text-color);
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: rgba(255, 255, 255, 0.6);
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	border-radius: 0 !important;
}

.page-loader {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	font-size: 0.8rem;
	text-align: center;
	z-index: 99999;

	mat-spinner {
		margin: 0 auto 15px;
	}
}

.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: var(--background);
	z-index: 9999;

	&.slightly-faded {
		background: rgba(0, 0, 0, 0.9);
	}

	&.faded {
		background: rgba(0, 0, 0, 0.5);
	}

	&.really-faded {
		background: rgba(0, 0, 0, 0.3);
	}
}

.get-started-container {
	border-radius: 6px;
	height: 100%;
	padding: 70px 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(255, 255, 255, 0.2);

	.content-container {
		position: relative;
	}

	.title {
		font-weight: bold;
	}

	.description {
		margin-bottom: 20px;
	}

	.actions {
		& > * {
			margin: 8px auto;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.flex-3-col {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;

	ion-item {
		margin-right: 10px;
		margin-bottom: 10px;
	}

	ion-radio,
	ion-checkbox {
		margin-right: 15px;
	}
}

app-overlay {
	.canvas-container,
	canvas,
	.upper-canvas {
		width: 100% !important;
		height: 100% !important;
	}
}

input[type='text'].dark {
	background: var(--foreground-color);
	color: var(--contrast-color);
	border: none;
	opacity: 1;
	padding: 6px 10px;

	&:focus,
	&:focus-visible {
		outline: none;
		border: none;
	}
}

.alert-bar {
	display: flex;
	padding: 5px 15px;
	background: var(--color-step-800);

	.left,
	.right {
		display: flex;
		align-items: center;
	}

	.left {
		flex: 1;
	}

	.right {
		flex: inherit;
		text-align: right;
	}

	&.error {
		background: #ff0000;
		color: var(--text-color);
	}

	&.success {
		background: #00ff00;
		color: black;
	}

	&.warning {
		background: #ffcc00;
		color: black;
	}
}

.input-hint {
	font-size: 0.7rem;
	font-weight: 300;
	color: var(--color-step-500);
}

.expandable-row-container {
	.properties {
		display: block;
		padding: 20px;
		background: rgba(255, 255, 255, 0.06);
		margin-bottom: 30px;
	}

	.card-row {
		border: 1px solid var(--color-step-650);
		align-items: center;
		padding-left: 10px;
		background: var(--color-step-750);
		cursor: pointer;

		p {
			margin: 0;
			flex: 1;
			padding: 10px 10px;
		}

		&.active {
			border-color: var(--color-step-600);
		}
	}
}

.gtb-loader {
	top: 3px;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	position: relative;
	animation: rotate 1s linear infinite;

	&.small {
		top: 0;
		width: 12px;
		height: 12px;
	}

	&::before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		inset: -11px;
		border-radius: 50%;
		border: 2px solid #fff;
		animation: prixClipFix 2s linear infinite;
	}

	&.small::before {
		inset: -5px;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes prixClipFix {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}
